import { JobSidebar } from '@/components';
import React from 'react';

type JobLayoutProps = {
  children: React.ReactNode;
};
const JobLayout = ({ children }: JobLayoutProps) => {
  return (
    <div className="container mx-auto px-2 lg:px-0 py-5">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
        <div className="md:col-span-4">{children}</div>
        <div className="md:col-span-2">
          <JobSidebar />
        </div>
      </div>
    </div>
  );
};

export default JobLayout;
