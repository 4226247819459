import { CookiesConsent, Footer, Header, Spinner } from '@/components';
import fetcher from '@/lib/fetcher';
import useSWR from 'swr';
import { ICompany } from '@/shared/types';

type AppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const { data: company, error } = useSWR<ICompany>('/api/company', fetcher);

  if (error) return null;
  if (!company) return null;

  return (
    <>
      <Header company={company} />
      {children}
      <Footer company={company} />
      <CookiesConsent />
    </>
  );
};

export default AppLayout;
