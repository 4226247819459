import { fetchJobTrends } from '@/services';
import { IJobTrend } from '@/shared/types';
import clsx from 'clsx';
import type * as CSS from 'csstype';
import { AppLayout } from 'layouts';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { ReactElement, ReactNode } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { SSR } from './_app';
import CarouselSlide from 'components/CarouselSlide';

const PAGE_HERO_BG_URL = '/images/backgrounds/Landing-Page_Head01.jpg';
const PAGE_JOB_HIGHLIGHT_BG_URL =
  '/images/backgrounds/VecteezyWhite-BackgroundRD0421_generated.webp';
const PAGE_ABOUT_BG_URL = '/images/backgrounds/11771164_4850037.webp';
const PAGE_OUTSOURCE_BG_URL =
  '/images/backgrounds/halftone-circle-background-vector.webp';
const PAGE_WHY_US_BG_URL = '/images/backgrounds/Landing-Page_04 Edit.jpg';
const PAGE_OUR_SERVICE_BG_URL = '/images/backgrounds/4858794.webp';
const PAGE_CUSTOMER_REVIEWS_BG_URL = '/images/backgrounds/rm373batch2-04.webp';

const HomeHero = () => {
  const wrapperStyles: CSS.Properties = {
    backgroundImage: `url('${PAGE_HERO_BG_URL}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  return (
    <div
      className="md:bg-left lg:bg-center xl:bg-right-top"
      style={wrapperStyles}
    >
      <div className="container mx-auto px-2 lg:px-0 py-32">
        <div className="flex flex-col space-y-4 text-center lg:text-left">
          <div className="text-primary text-xl lg:text-2xl font-medium">
            <span>Perfect Hunter Recruitment</span>
          </div>
          <p className="font-bold text-3xl lg:text-4xl lg:leading-relaxed lg:max-w-lg">
            คัดสรรบุคลากรที่มี
            <span className="block lg:inline-block">"คุณภาพ"</span>
            <span className="block lg:inline-block">บริการรวดเร็ว</span>
            <span className="block lg:inline-block">และมีประสบการณ์</span>
            <span className="block lg:inline-block">ผลงานมากกว่า 10 ปี</span>
          </p>
          <span className="font-medium text-xl">
            บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด
          </span>
          <div>
            <Link href="/apply-job">
              <a className="btn btn-primary">สมัครงานกับเรา</a>
            </Link>
          </div>
          <div>
            <Link href="/contact-us#form">
              <a className="btn btn-primary">ค้นหาพนักงานคนต่อไป</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const JobHighlightItem = (props: IJobTrend) => {
  return (
    <Link href={`/jobs/${props.id}`}>
      <a className="flex flex-col space-y-2 bg-white p-6 md:p-7 rounded-md shadow-lg hover:shadow-xl">
        <span>{props.company_name}</span>
        <p className="text-primary font-medium">{props.name}</p>
        <div className="flex text-sm">
          <i className="fi fi-rr-briefcase text-gray-500 text-lg w-5 h-5 mr-2.5" />
          {props.job_type_name}
        </div>
        <div className="flex text-sm">
          <i className="fi fi-rr-marker text-gray-500 text-lg w-5 h-5 mr-2.5" />
          {props.location}
        </div>
        <div className="flex text-sm">
          <i className="fi fi-rr-money-check-edit text-gray-500 text-lg w-5 h-5 mr-2.5" />
          {props.salary}
        </div>
      </a>
    </Link>
  );
};

const JobHighlight = ({ jobs }: { jobs: IJobTrend[] }) => {
  const wrapperStyles: CSS.Properties = {
    backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url('${PAGE_JOB_HIGHLIGHT_BG_URL}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <section id="job-trending" style={wrapperStyles}>
      <div className="container mx-auto px-2 lg:px-0 py-14">
        <div className="flex flex-col space-y-10">
          {/* begin: title */}
          <div className="lg:flex lg:justify-between lg:items-center">
            <div className="text-center lg:text-left">
              <span className="text-xl text-primary font-medium">
                ตำแหน่งงานน่าสนใจ
              </span>
              <h3 className="text-2xl font-semibold">
                เรามีตำแหน่งงานมากมาย{' '}
                <span className="block md:inline-block">
                  ที่ไว้วางใจให้เราดูแล
                </span>
              </h3>
            </div>
            <div className="hidden lg:block text-center">
              <Link href="/jobs">
                <a className="btn btn-primary">ตำแหน่งงานทั้งหมด</a>
              </Link>
            </div>
          </div>
          {/* end: title */}
          {/* begin: content */}
          <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {jobs.map((job) => (
              <JobHighlightItem {...job} key={job.id} />
            ))}
          </div>
          {/* end: content */}
          <div className="block lg:hidden text-center">
            <Link href="/jobs">
              <a className="btn btn-primary">ตำแหน่งงานทั้งหมด</a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const CTLBanner = () => {
  return (
    <div className="container mx-auto px-2">
      <Link href="/payroll" passHref legacyBehavior>
        <Image
          src="/images/Landing-Page-CTL Edit.jpg"
          alt="ctl banner"
          height={871}
          width={2732}
          layout="responsive"
        />
      </Link>
    </div>
  );
};

const About = () => {
  const wrapperStyles: CSS.Properties = {
    backgroundImage: `url('${PAGE_ABOUT_BG_URL}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <section id="about" style={wrapperStyles}>
      <div className="container mx-auto px-2 lg:px-0 py-14">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="relative aspect-square">
            <Image
              src="/images/Landing-Page_01.jpg"
              alt="businesspeople-information"
              layout="fill"
              objectFit="cover"
              className="rounded-3xl md:rounded-r-3xl"
            />
          </div>
          <div className="flex flex-col space-y-2.5">
            <span className="text-xl md:text-2xl text-primary font-medium">
              เกี่ยวกับเรา
            </span>
            <h3 className="text-3xl md:text-4xl font-bold">
              บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด
            </h3>
            <p className="text-xl font-medium">
              คัดสรรบุคลากรที่มี “คุณภาพ” บริการรวดเร็วและประสบการณ์มากกว่า 10
              ปี
            </p>
            <p>
              บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์
              จำกัดเป็นบริษัทที่ปรึกษาและพัฒนาด้านบุคลากร
              ปัจจุบันเรามีที่ปรึกษาบุคลากรที่มีความรู้ความสามารถ
              เชี่ยวชาญในแต่ละสาขาอาชีพเป็นอย่างดี
              จึงทำให้บริษัทลูกค้ามั่นใจได้ว่าจะได้ผู้สมัครงานตามตำแหน่งที่ต้องการ
            </p>
            <p>
              ก่อตั้งขึ้นเมื่อ 24 มีนาคม 2552 โดยมี ผศ.อวยชัย วงศ์รัตน์
              เป็นที่ปรึกษาบริษัท
              เน้นให้บริการสรรหาบุคลากรในประเทศโดยมีพัฒนาการการเติบโตจดทะเบียนถูกต้องตามกฎหมายภายใต้การดูแลของกรมแรงงานตามใบอนุญาตเลขที่
              น.1284/2555
            </p>
            <span className="block font-medium">จุดเด่นการให้บริการ</span>
            <ul className="list-inside list-disc">
              <li>ประหยัดเวลาและลดค่าใช้จ่ายในการสรรหา</li>
              <li>แบ่งเบาภาระฝ่ายบุคคล</li>
              <li>
                คัดเลือกบุคลากรที่มีคุณสมบัติตรงตามความต้องการและสามารถขอเปลี่ยนแปลงบุคลากรได้
              </li>
              <li>มีระยะเวลารับประกัน</li>
              <li>ไม่คิดค่าบริการจนกว่าจะพอใจ</li>
            </ul>
            <p>
              จากการเติบโตอย่างต่อเนื่องของ บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์
              จำกัด ทำให้เป็น ที่ยอมรับจากบริษัทชั้นนำภายในและต่างประเทศมากกว่า
              100 บริษัท ซึ่งบริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด
              มีแผนที่จะขยายธุรกิจการให้บริการในภูมิภาคเอเชียตะวันออกเฉียงใต้ในไม่ช้า
              เพื่อสร้างความภาคภูมิใจที่อุตสาหกรรมบริการของไทยสามารถก้าวไกลไปต่างประเทศ
              ภายใต้ สโลแกนที่ว่า{' '}
              <span className="font-bold text-primary">
                "คุณธรรมน้อมนำคุณภาพ"
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const OutSource = () => {
  const wrapperStyles: CSS.Properties = {
    backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('${PAGE_OUTSOURCE_BG_URL}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <section id="outsource" style={wrapperStyles}>
      <div className="container mx-auto px-2 lg:px-0 py-14">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-6">
          <div className="flex flex-col space-y-2.5">
            <span className="text-xl md:text-2xl text-primary font-medium">
              การจ้างบุคคลภายนอก/พนักงานชั่วคราว (Outsource)
            </span>
            <h3 className="text-2xl md:text-3xl font-bold">
              บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด
            </h3>
            <p>
              บริษัทดำเนินการสรรหาและคัดเลือกผู้สมัครงานเข้าสัมภาษณ์งาน
              ตามคุณสมบัติพื้นฐานที่ลูกค้ากำหนด โดย บริษัทจัดหางาน เพอร์เฟค
              ฮันเตอร์ จำกัด จะเป็นผู้ดำเนินการบริการจัดการทั้งหมดในส่วนของ
              การจ่ายค่าจ้าง, เงินเดือน, สวัสดิการ,
              ภาษีและประกันสังคมให้แก่ลูกจ้างโดยตรง
              และจะเรียกเก็บค่าบริการจากบริษัทลูกค้าเป็น รายเดือน
              โดยที่บริษัทลูกค้าไม่ต้องกังวลเรื่องต้นทุนที่จะเพิ่มขึ้น
              หรือมีพันธะผูกพันใดๆ หลังเสร็จสิ้นกำหนดระยะเวลา ที่ว่าจ้าง
              (ทั้งนี้บริษัทลูกค้าสามารถกำหนดระยะเวลาการว่าจ้างได้ตั้งแต่ 6
              เดือนขึ้นไป โดยไม่มีกำหนดเงินเดือนขั้นต่ำ)
            </p>
            <p>
              กรณีที่บริษัทลูกค้ามีลูกจ้างชั่วคราวอยู่แล้ว
              สามารถโอนลูกจ้างที่มีอยู่ให้ บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์
              จำกัด ทำหน้าที่ดูแลได้เช่นกัน โดยบริษัทจัดหางาน เพอร์เฟค ฮันเตอร์
              จำกัด จะคิดค่าบริการในราคาพิเศษให้กับ ทางบริษัทลูกค้า
            </p>
          </div>
          <div className="relative aspect-video">
            <Image
              src="/images/Landing-Page_02.jpg"
              alt="two-biz-management"
              layout="fill"
              objectFit="cover"
              className="rounded-3xl md:rounded-r-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const WhyUs = () => {
  const quoteStyles: CSS.Properties = {
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${PAGE_WHY_US_BG_URL}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <section id="about" className="">
      <div className="container mx-auto px-2 lg:px-0 py-14">
        <div className="grid lg:grid-cols-2  items-center gap-4">
          <div className="relative aspect-video">
            <Image
              src="/images/Landing-Page_03.jpg"
              alt="biz-team-ready"
              layout="fill"
              objectFit="cover"
              className="rounded-3xl md:rounded-r-3xl"
            />
          </div>
          <div>
            <span className="text-xl md:text-2xl text-primary font-medium">
              ทำไมต้องเป็นเรา?
            </span>
            <ul className="list-inside list-disc mt-5">
              <li>คิดค่าบริการง่าย</li>
              <li>ค่าบริการถูกหากเทียบเป็น %</li>
              <li>ค่าบริการเท่ากันทุกตำแหน่งลูกค้าสามารถเลือกได้ด้วยตัวเอง</li>
              <li>ยินดีทดแทนหรือคืนเงินขึ้นอยู่กับความต้องการของลูกค้า</li>
              <li>ยิ่งใช้บริการมากราคายิ่งถูก</li>
              <li>ส่งประวัติผู้สมัครให้พิจารณาภายใน 3-5 วัน</li>
              <li>บริการเช็คประวัติย้อนหลังฟรี</li>
              <li>ไม่คิดค่าบริการจนกว่าลูกค้าจะรับพนักงานเข้าทำงาน</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid grid-col-1 lg:grid-cols-2 bg-primary">
        <div className="grid gap-4 justify-center items-center py-10 px-2 lg:px-2 text-white">
          <div className="flex flex-col gap-4  md:max-w-xl lg:max-w-2xl">
            <span className="text-xl md:text-2xl font-medium">
              แบ่งเบาภาระงานฝ่ายบุคคล
            </span>
            <p className="text-lg md:text-xl">
              บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด
              ช่วยแบ่งเบาภาระงานของแผนกบุคคลใน การสรรหาบุคลากรเข้าทำงาน ดังนี้
            </p>
            <ul className="list-outside list-disc pl-5 leading-relaxed">
              <li>แบ่งเบาภาระค่าใช้จ่ายในการลงโฆษณาสื่อต่างๆ, ค่าโทรศัพท์</li>
              <li>
                แบ่งเบาภาระเรื่องของเวลาในการลงสื่อโฆษณา, ระยะเวลาในการโทรศัพท์
                รวมถึงการ
                เสียเวลาสัมภาษณ์ผู้สมัครงานที่มีคุณสมบัติไม่ตรงตามที่ต้องการ
              </li>
              <li>
                แบ่งเบาภาระการลาออกบ่อยๆ ของผู้สมัครงาน
                เพราะหากผู้สมัครงานลาออกก่อนหมด ระยะเวลารับประกัน
                ทางบริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด ยินดีหาผู้สมัครงาน
                มาทดแทนให้ โดยบริษัทลูกค้าไม่เสียค่าใช้จ่ายเพิ่มอีก
              </li>
            </ul>
          </div>
        </div>
        <div
          className="flex flex-col space-y-4 py-6 md:py-10 px-2 lg:px-2 justify-center items-center text-white text-center min-h-[428px]"
          style={quoteStyles}
        >
          <div className="md:max-w-sm lg:max-w-lg">
            <div className="text-center mb-4">
              <span className="text-2xl font-medium text-white">
                PERFECT HUNTER RECRUITMENT
              </span>
              <span className="text-xl font-medium text-white block">
                บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด
              </span>
            </div>
            <p>
              ยินดีจัดส่งผู้สมัครงานให้บริษัทลูกค้าสัมภาษณ์ ทันที
              โดยไม่เสียค่าใช้จ่ายใด ๆ จนกว่าจะพอใจ
              ซึ่งจะเป็นอีกทางเลือกหนึ่งในการประหยัด
              งบประมาณและช่วยคัดสรรผู้สมัครงานที่มีคุณภาพให้กับบริษัทลูกค้าอีกด้วย
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const OurServiceItem = (props: {
  icon: string;
  title: string;
  message: string;
}) => {
  return (
    <div className="bg-white p-7 rounded-md shadow-lg">
      <div className="flex flex-col space-y-2.5">
        <i
          className={clsx(
            'fi',
            props.icon,
            'w-12 h-12',
            'text-5xl text-primary'
          )}
        />
        <span className="font-bold text-xl">{props.title}</span>
        <p className="text-base-300">{props.message}</p>
      </div>
    </div>
  );
};

const OurServices = () => {
  const wrapperStyles: CSS.Properties = {
    backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('${PAGE_OUR_SERVICE_BG_URL}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <section id="why-us" style={wrapperStyles}>
      <div className="container mx-auto px-2 lg:px-0 py-14">
        <div className="text-center">
          <span className="text-xl text-primary font-medium">บริการของเรา</span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-20">
          <OurServiceItem
            icon="fi-rr-building"
            title="การแลกเปลี่ยนข้อมูลกับลูกค้า"
            message="ก่อนดำเนินการคัดสรรผู้สมัครงานทางเรา จะรวบรวมข้อมูล บริษัทลูกค้า ที่เป็นข้อมูลที่ปัจจุบันมากที่สุด ซึ่งข้อมูลเหล่านี้จะ เป็นข้อมูลสำคัญให้ผู้สมัครงานได้ตัดสินใจในการร่วมทำงานกับบริษัทลูกค้า อันจะนำไปสู่การสรรหาผู้สมัครงานที่เหมาะสมและตรงกับความต้องการของบริษัทลูกค้ามากที่สุด"
          />
          <OurServiceItem
            icon="fi-rr-document-signed"
            title="สรรหาพนักงาน"
            message="ทางเราจะสรรหาพนักงานจากฐานข้อมูลที่เรามีอยู่โดยเฉพาะหรืออีกหลากหลายช่องทาง และ ก่อนที่เราจะส่งผู้สมัครให้ลูกค้าเลือก เราจะมีการจัดทำเรซูเม่โดยใช้แอพพลิเคชั่น ฟอร์มจากบริษัทของเรา เพื่อนำส่งให้กับลูกค้าได้พิจารณาเลือกผู้สมัครเพื่อเข้าไปสัมภาษณ์จริง"
          />
          <OurServiceItem
            icon="fi-rr-comment"
            title="สัมภาษณ์ผู้สมัคร"
            message="หลังจากที่เราสรรหาผู้สมัครได้แล้ว จะมีการสัมภาษณ์เบื้องต้นตามความต้องการของลูกค้าให้ได้มากที่สุด โดยผ่านทางโทรศัพท์ หรือ ทางออนไลน์ เพื่อให้มั่นใจว่าผู้สมัครที่ทางเรากำลังจะส่งต่อให้ลูกค้า มีประวัติการทำงานที่ตรงกับความต้องการของบริษัทลูกค้ามากที่สุด"
          />
          <OurServiceItem
            icon="fi-rr-lock"
            title="ประเมินความสามารถผู้สมัคร"
            message="หลังจากการสัมภาษณ์เบื้องต้นแล้ว ทางเรายังมีบริการวัดความรู้ความสามารถของผู้สมัครงาน ให้ผู้สมัครงานได้ทำแบบทดสอบเฉพาะทางต่างๆ ตามที่ลูกค้าต้องการได้ เช่น การทดสอบความสามารถด้านภาษา, แบบทดสอบ IQ, หรือแบบทดสอบโปรแกรมเฉพาะ"
          />
          <OurServiceItem
            icon="fi-rr-comments-question"
            title="เช็คประวัติอาชญากรรมและการทำงานย้อนหลัง"
            message="เพื่อความปลอดภัยของลูกค้า ทางเรามีบริการเสริมเช็คประวัติอาชญากรรมของผู้สมัครงานที่ถูกคัดเลือกแล้ว โดยอยู่ภายใต้เงื่อนไขการยินยอมจากผู้สมัคร และสามารถช่วยลูกค้าเช็คประวัติการทำงานย้อนหลังของผู้สมัครได้ เพื่อให้มั่นใจได้ว่าลูกค้าจะได้พนักงานที่มีคุณภาพเหมาะสมกับบริษัทลูกค้าที่สุด"
          />
          <OurServiceItem
            icon="fi-rr-edit"
            title="ปรึกษาด้านงานฝ่ายบุคคล"
            message="บุคลากรของบริษัท จัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด ได้รับการฝึกฝนและมีประสบการณ์การสรรหาพนักงาน จึงสามารถเป็นที่ปรึกษาให้กับลูกค้าได้ ไม่ใช่แค่เรื่องการสรรหาพนักงานเท่านั้น ยังสามารถสอบถามเกี่ยวกับเรื่องของกฎหมายแรงงานเบื้องต้นหรือ ข้อมูลฝ่ายบุคคลได้อีกด้วย"
          />
          <OurServiceItem
            icon="fi-rr-balance-scale-right"
            title="การรับประกันผู้สมัคร"
            message="เมื่อลูกค้าตกลงที่จะรับผู้สมัครจากเราแล้ว ทางเราจะมีการรับประกันผู้สมัครคนนั้น ตามเงื่อนไขระยะเวลาที่ได้เซ็นสัญญากันไว้ กรณีผู้สมัครไม่ผ่านการทดลองงาน ทางเราจะทำการสรรหาพนักงานทดแทนให้ใหม่ 1 ครั้ง โดยที่ลูกค้าไม่ต้องจ่ายค่าบริการเพิ่มเติม"
          />
          <OurServiceItem
            icon="fi-rr-shield-check"
            title="รักษาความลับ"
            message='การรักษาความลับของข้อมูลที่ได้รับจากทางบริษัทลูกค้าอย่างซื่อสัตย์ ระมัดระวัง ข้อมูลจะถูกเก็บรักษาอย่างดี จะไม่มีการขายหรือส่งต่อใดใดทั้งสิ้น เพราะบริษัทเล็งเห็นและให้ความสำคัญกับเรื่องนี้มาก เรายึดหลักการทำงาน ตามสโลแกนที่ว่า "คุณธรรมน้อมนำคุณภาพ"'
          />
        </div>
      </div>
    </section>
  );
};

const AliceCarouselItem = (props: {
  avatarSrc: string;
  name: string;
  position: string;
}) => {
  return (
    <div className="text-white p-7">
      <div className="grid gap-6 justify-center">
        <div className="avatar mx-auto">
          <Image
            src={`/images/avatars/${props.avatarSrc}`}
            alt={props.avatarSrc}
            layout="fixed"
            width={150}
            height={150}
            className="rounded-full"
          />
        </div>
        <p className="text-center font-medium">
          {props.name}{' '}
          <span className="block font-normal">{props.position}</span>
        </p>
      </div>
    </div>
  );
};
const CustomerReviews = () => {
  const wrapperStyles: CSS.Properties = {
    backgroundImage: `linear-gradient(180deg, rgba(26, 28, 30, 0) 0%, rgba(11, 74, 143, 0.68) 100%), linear-gradient(0deg, rgba(4, 77, 151, 0.76), rgba(4, 77, 151, 0.76)), url('${PAGE_CUSTOMER_REVIEWS_BG_URL}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 3 },
    1024: { items: 5 },
  };

  const items = [
    <AliceCarouselItem
      avatarSrc="Profile_01.png"
      name="Rapeeporn Sae-Yee"
      position="Marketing & Recruitment Manager"
      key={1}
    />,
    <AliceCarouselItem
      avatarSrc="Profile_02.png"
      name="Sorada Areekarn"
      position="Assistant Recruitment Manager"
      key={2}
    />,
    <AliceCarouselItem
      avatarSrc="Profile_03.png"
      name="Wipawan Kaewchaisri"
      position="Accounting Manager"
      key={3}
    />,
    <AliceCarouselItem
      avatarSrc="Profile_04.png"
      name="Charasthip Takanchan"
      position="Outsourcing Sales & Recruitment Service"
      key={4}
    />,
    <AliceCarouselItem
      avatarSrc="Profile_05.png"
      name="Wassana Inchoy"
      position="Head of Payroll & Recruitment Service"
      key={5}
    />,
    <AliceCarouselItem
      avatarSrc="Profile_06.png"
      name="Piyaporn Wongrat"
      position="Managing Director"
      key={6}
    />,
  ];

  return (
    <section id="customer-review" className="bg-center" style={wrapperStyles}>
      <div className="container mx-auto px-2 lg:px-0 py-14">
        <h3 className="text-xl font-medium text-white text-center mb-10">
          Operations Team
        </h3>
        <AliceCarousel
          items={items}
          autoPlayInterval={3000}
          responsive={responsive}
          controlsStrategy="alternate"
          autoPlay
          mouseTracking
          infinite
          disableButtonsControls
        />
      </div>
    </section>
  );
};

export const partnerLogos = [
  'global-chemie-ascc.jpg',
  'baby-pool-thailand.jpg',
  'amabelle.jpg',
  'the-first-thai-brush.jpg',
  'ottobock.jpg',
  'smith-and-nephew.jpg',
  'tll.jpg',
  'siam-pvs- chemicals.jpg',
  'united-winery-and-distillery.jpg',
  'cool-innotech.jpg',
  'paw-food.jpg',
  'Customer-Logo-1.jpg',
  'Customer-Logo-2.jpg',
  'Customer-Logo-3.jpg',
  'Customer-Logo-4.jpg',
  'Customer-Logo-5.jpg',
  'Customer-Logo-6.jpg',
  'Customer-Logo-7.jpg',
  'Customer-Logo-8.jpg',
];
const Partners = () => {
  return (
    <section id="partners">
      <div className="container mx-auto px-2 lg:px-0 py-14">
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4">
          {partnerLogos.map((logo, index) => (
            <div className="relative h-20" key={index}>
              <Image
                src={`/images/logos/partners/${logo}`}
                alt={logo}
                layout="fill"
                objectFit="contain"
              />
            </div>
          ))}
        </div>
      </div>
      <CarouselSlide />
    </section>
  );
};

export const getServerSideProps: GetServerSideProps<{
  jobTrends: IJobTrend[];
}> = async (_) => {
  const jobTrends = await fetchJobTrends(8);

  return {
    props: {
      jobTrends,
    },
  };
};

const Home: SSR<typeof getServerSideProps> = (props) => {
  return (
    <div>
      <Head>
        <title>{process.env.COMPANY_NAME}</title>
        <meta
          name="description"
          content="บริษัทที่ปรึกษาและพัฒนาด้านบุคลากร ปัจจุบันเรามีที่ปรึกษาบุคลากรที่มีความรู้ความสามารถ เชี่ยวชาญในแต่ละสาขาอาชีพเป็นอย่างดี จึงทำให้บริษัทลูกค้ามั่นใจได้ว่าจะได้ผู้สมัครงานตามตำแหน่งที่ต้องการ"
        />
      </Head>

      <main>
        {/* begin: hero section */}
        <HomeHero />
        {/* end: hero section */}
        {/* begin: job trending list section */}
        <JobHighlight jobs={props.jobTrends} />
        {/* end: job trending list section */}
        <CTLBanner />
        {/* begin: about us section */}
        <About />
        {/* end: about us section */}
        {/* begin: our source section */}
        <OutSource />
        {/* end: our source section */}
        {/* begin: why us section */}
        <WhyUs />
        {/* end: why us section */}
        {/* begin: our services section */}
        <OurServices />
        {/* end: our services section */}
        {/* begin: customer review section */}
        <CustomerReviews />
        {/* end: customer review section */}
        {/* begin: partner section */}
        <Partners />
        {/* end: partner section */}
      </main>
    </div>
  );
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <AppLayout>{page}</AppLayout>;
};

export default Home;
